import React from 'react';
import { Alert, AlertTitle, Box } from '@mui/material';

const BackAlert = ({ title, message, titleProps = {}, messageProps = {}, ...rest }) => {
  return (
    <Alert data-testid="alert" {...rest} style={{ marginTop: 10 }}>
      {title && (
        <AlertTitle {...titleProps} data-testid="alert-tittle">
          {title}
        </AlertTitle>
      )}
      <div data-testid="alert-message" {...messageProps}>
        {message}
      </div>
    </Alert>
  );
};
export default BackAlert;
