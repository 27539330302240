import React, { useEffect, useState } from 'react';
import { Box, Button, FormHelperText, TextField, InputAdornment, IconButton, CircularProgress } from '@mui/material';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Alert from '../../alert';
import useAlert from '../../../hooks/useAlert';
import useAuth from '../../../hooks/useAuth';
import { useRouter } from 'next/router';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { useTranslation } from 'hooks/useTranslation';

const LoginForm = () => {
  const router = useRouter();
  const isMountedRef = useIsMountedRef();
  const { user, login, error, loading } = useAuth();
  const [show, setShow] = useState('password');
  const { setAlert, alert } = useAlert();
  const { translate } = useTranslation();

  const handleClickShowPassword = () => {
    if (show === 'password') {
      setShow('text');
    } else {
      setShow('password');
    }
  };

  useEffect(() => {
    if (error) {
      setAlert({ message: error, type: 'error' });
    }
    if (user && user._id) {
      setAlert({ message: translate('loginSuccess'), type: 'success' });
      router.push('/dashboard');
    }
    //eslint-disable-next-line
  }, [error, user]);

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email(translate('emailRequired')).max(255).required(translate('emailRequired')),
          password: Yup.string().max(255).required(translate('passRequired'))
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            login(values);

            if (isMountedRef.current) {
              if (!error) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            }
          } catch (err: any) {
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.error });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form style={{ width: '100%' }} noValidate onSubmit={handleSubmit}>
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              autoFocus
              helperText={touched.email && errors.email}
              label={translate('email')}
              margin="dense"
              name="email"
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange({
                  target: {
                    name: 'email',
                    value: e.target.value.trim()
                  }
                });
              }}
              type="email"
              value={values.email}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />

            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              margin="dense"
              label={translate('password')}
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type={show}
              value={values.password}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                      {show === 'password' ? <VisibilityIcon /> : <VisibilityOffIcon />}{' '}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            {alert && alert.message && (
              <Alert
                title=""
                titleProps={{ style: { margin: 0, padding: 0 } }}
                messageProps={{ style: { margin: 0, padding: 0 } }}
                severity={alert.type}
                message={translate(alert.message)}
              />
            )}
            <Box mt={1} mb={1}>
              <Button
                color="primary"
                disabled={isSubmitting || loading || Boolean(user && user._id)}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {translate('login')}

                {loading ? <CircularProgress size={15} thickness={7} color="inherit" sx={{ marginLeft: '.5rem' }} /> : null}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

LoginForm.propTypes = {
  className: PropTypes.string
};

export default LoginForm;
