import React, { ReactElement, useEffect, useState } from 'react';
import AuthCardWrapper from 'components/authentication/AuthCardWrapper';
import AuthLogin from 'components/authentication/auth-forms/AuthLogin';
import { Stack, Typography, Box, Container } from '@mui/material';
import { useTheme } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import Page from 'ui-component/Page';
import Layout from '../../layout';
import Logo from 'ui-component/Logo';
import { useTranslation } from 'hooks/useTranslation';
import { useRouter } from 'next/router';
import { DASHBOARD_PATH } from 'config';
import useAuth from 'hooks/useAuth';

const Login = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const { translate } = useTranslation();
  const { isAuthenticated, loadUser } = useAuth();

  const router = useRouter();

  const handleLoadUser = () => {
    if (isAuthenticated) return router.push(`/${DASHBOARD_PATH}`);

    const token = localStorage.getItem('token');
    if (token) return loadUser();
  };

  useEffect(() => {
    handleLoadUser();
  }, [isAuthenticated]);

  return (
    <Page title="login">
      <Box
        sx={{
          backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.grey[100],
          minHeight: '100vh',
          marginBottom: { xs: '30px', md: '0px' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <AuthCardWrapper>
            <Box sx={{ textAlign: 'center', mb: 1 }}>
              <Logo />
            </Box>
            <Stack alignItems="center" justifyContent="center" spacing={1}>
              <Typography data-testid="login-text" variant="caption" fontSize="16px" textAlign={matchDownSM ? 'center' : 'inherit'}>
                {translate('loginCredentials')}
              </Typography>
            </Stack>
            <AuthLogin />
          </AuthCardWrapper>
        </Container>
      </Box>
    </Page>
  );
};

Login.getLayout = function getLayout(page: ReactElement) {
  return <Layout variant="minimal">{page}</Layout>;
};

export default Login;
